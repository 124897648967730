<template>
  <div id="app">
    <div v-if="listLength&&ishome" class="nulls">
              <div class="card-box">
                <div class="card-info">
                  <div class="card-avatar"><img src="../public/photo/LOGO.png" width="60px" height="60px"></div>
                    <div class="card-title">暂无文件 悬浮鼠标上传文件</div>
                    <ul class="card-social">
                      <li class="card-social__item">
                        <button class="button-box" @click="upload()">上传文件</button>
                      </li>
                      <li class="card-social__item">
                        <button class="button-box" @click="dialogVisible = true">新建文件夹</button>
                      </li>
                    </ul>
                </div>
              </div>
    </div>

      <div v-if="usert=='true'" :class="index&&usert=='true' ?'Toptaskbar':'Toptaskbar2'">
          <el-breadcrumb  separator=">">
              <el-breadcrumb-item v-for="(item,index) in 1" :to="{ path: '/' }" :replace ="true">{{routeTitle}}</el-breadcrumb-item>
          </el-breadcrumb>

          <div class="searchbox">
             <el-input
              placeholder="搜索内容"
              prefix-icon="el-icon-search"
              v-model="searchText">
            </el-input>
          </div>
          <div class="expandbox">
            <el-dropdown trigger="click" placement="top-start">
              <span class="el-dropdown-link">
                <i class="el-icon-circle-plus-outline"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="upload()" >上传文件</el-dropdown-item>
                <el-dropdown-item @click.native="dialogVisible = true">新建文件夹</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div @click="uploadlist=!uploadlist"  class="transmission" title="上传列表">
            <i class="el-icon-upload2"></i>
          </div>
      </div>

      <div :class="index&&usert=='true' ? 'sidebar':'sidebar2'">
        <div class="LOGO">
          <img class="logos"  src="../public/photo/LOGO.png" width="50" height="50px">
          <span class="LOGO-text">speed云盘</span>
        </div>
       <div class="User">
          <div class="hrad">
            <i class="el-icon-user-solid"></i>
          </div>
          <span v-if="uName" style="margin-left: 10px;font-size: 18px;">{{uName}}</span>
          <div class="foot">
            <el-dropdown trigger="click" placement="top-start">
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="gto()">个人中心</el-dropdown-item>
                <!-- 
                <el-dropdown-item >我的分享</el-dropdown-item>
                <el-dropdown-item >待定</el-dropdown-item>
                <el-dropdown-item >待定</el-dropdown-item> -->
                <el-dropdown-item @click.native="cancellation()">退出登入</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

       </div>

       <div class="card" >
        <li style="list-style-type: none;float: left;" v-for="index in 3" :key="index">
          <div v-if="index!=sidebarnum" @mouseover="ind=index" @mouseleave="ind=null" @click="Sidebars(index)" :class="ind==index ? 'cards2':'cards'">
            <img class="che"  :src="require('../public/photo/che'+(index)+'.png')" >
            <span class="textr">{{title[index-1]}}</span>
          </div>
          <div v-else class="cards3">
              <img class="che"  :src="require('../public/photo/che'+(index)+'.png')" >
              <span class="textr">{{title[index-1]}}</span>
          </div>
        </li>
       </div>

       <div class="foots">
        <span class="foots-text">{{usedspace}} GB / {{allspace}} GB</span>
        <span class="foots-text2">云盘容量</span>
        <el-progress v-if="usedspace&&allspace" style="margin-top: 10px;"  :percentage="(usedspace/allspace)*100" :show-text="false"></el-progress>
       </div>

      </div>
      <div v-if="usert=='true'" :class="index ? 'anniu':'anniu2'">
            <i v-if="index==true" @click="types()" class="el-icon-caret-left"></i>
            <i v-else  @click="types()" class="el-icon-caret-right"></i>
      </div>
      <div :class="index&&usert=='true' ? 'bodu':'bodu2'">
        <transition name="fade">
            <router-view v-if="isRouterAlive" />
        </transition>
      </div>



      <el-dialog
        title="新建文件夹"
        :visible.sync="dialogVisible"
        width="400px">
        <div class="NewFolder"><img  src="../public/photo/folder.png" width="100px" height="80px"></div>
        <div style="margin-top: 30px;"><el-input v-model="NewFolderName" placeholder="文件夹名称"></el-input></div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addFolder()">确 定</el-button>
        </span>
      </el-dialog>

      
      <!-- 上传列表 -->
      <div  :class="uploadlist?'uploadbox2':'uploadbox'">
        <div class="uploadtext">上传列表</div>
        <div class="xuanfu"></div>
        <div class="upload-box">
          <li v-if="filelist" v-for="item,index in filelist[0]" :key="index" style="list-style-type: none;">
            <div class="bottombox" :style="'width:'+(item.jindu+1)+'%;'"></div>
            <div class="n-uploadbox" >
              <div style="height:10px;"></div>
              <div class="imgs">
                <img :src="require('../public/photo/'+item.type+'.png')" width="60px" height="60px">
              </div>
              <div style="margin-left: 95px;margin-top: -60px;font-size:18px;width:190px;-webkit-box-orient: vertical;display: -webkit-box;overflow-wrap: break-word;overflow:hidden;text-overflow:ellipsis;-webkit-line-clamp:1;">{{item.name}}</div>
              <div style="margin-left: 95px;margin-top: 15px;font-size:14px;">{{item.size}}</div>
              <div v-if="(item.jindu+1)>0&&(item.jindu+1)<100" style="margin-left: 340px;margin-top: -10px;font-size:16px;">{{item.jindu+1}}%</div>
              <div v-if="(item.jindu+1)<=0" style="margin-left: 320px;margin-top: -40px;font-size:18px;">解析中</div>
              <div v-if="item.jindu==100" style="margin-left: 300px;margin-top: -40px;font-size:18px;">上传完成</div>
              <div v-if="(item.jindu+1)>0&&(item.jindu+1)<100"  style="margin-left: 300px;margin-top: -60px;font-size:30px;cursor: pointer;position: absolute;">
                <div v-if="(item.bott)" @click="zan(index)"><i class="el-icon-video-pause"></i></div>
                <div v-else @click="ji(index)"><i class="el-icon-caret-right"></i></div>
              </div>
              <div v-if="(item.jindu+1)>0&&(item.jindu+1)<100"  style="margin-left: 360px;margin-top: -80px;font-size:22px;cursor: pointer;position: absolute;">
                <i @click="guanbi(index)" class="el-icon-close"></i>
              </div>
            </div>
            <div v-if="index==filelist[0].length-1" style="height:30px"></div>
          </li>
        </div>
      </div>
      <uploads v-show="false" ref="uploads" v-on:numdata="uploaddata" v-on:jindu="uploadjindu"></uploads>
      </div>
      
  </div>
</template>

<script type="text/javascript">
import home from '../src/components/home.vue'
import SparkMD5 from 'spark-md5'
import upload from '../src/upload.vue'
import {endUpload,addfolder,getUser} from '../src/apis/index'
   export default {
    components: {
    'uploads': upload,
  },
  provide(){
    return {
      reload:this.reload
    }
  },
   data() {
       return {
          //用户名
          uName:null,
          //总容量
          allspace:null,
          usedspace:null,
          //当前页面是否尚未有文件
          listLength:false,
          //当前是否为主页或文件夹页
          ishome:true,
          //当前路由标签
          routeTitle:sessionStorage.getItem('zhutitle'),
          isRouterAlive:true,
          uploadlist:false,
          index:sessionStorage.getItem('sidebartype')=='true',
          usert:sessionStorage.getItem('users'),
          ind:100,
          sidebarnum:1,
          routers:['/home','/file','/recycle'],
          title:['全部文件','我的隐私','回收站'],
          //搜索框内容
          searchText:null,
          //新建文件夹层是否渲染变量
          dialogVisible:false,
          //新建文件夹名称
          NewFolderName:null,
          //上传文件列表
          filelist:[
            // {
            //   0:{jindu:68,
            //   type:'mp4',
            //   bott:false,
            //   name:"测试文件阿斯顿耨爱无能到位.mp4",
            //   size:"597.6MB"},
            // }
          ],
          //默认上传文件列表长度
          num:0,
          //暂停或继续
          stops:true,

       }
   },

   watch: {
    '$route' () {
      //判断是否登录
      if(this.$route.path=='/folder'||this.$route.path=='/home'){
        this.ishome = true
      }else{
        this.ishome = false
      }

      if(this.$route.path!='/folder'){
        sessionStorage.removeItem('paths')
      }else{
        sessionStorage.setItem('paths',this.getQueryString('belong'))
      }
      this.initData();
      //路由跳转时清空搜索框内容
      this.searchText = null
      //路由跳转时获得当前路由的标签
      if(this.$route.meta.title){
        sessionStorage.setItem('zhutitle',this.$route.meta.title)
        this.routeTitle = sessionStorage.getItem('zhutitle')
      }
      // 路由跳转侧边栏跟着变动
      //  console.log(226)
      // if(this.$route.path=='/home'){
      //   sessionStorage.setItem('sidebar',''+1)
      //   this.sidebarnum = 1
      // }else if(this.$route.path=='/folder'){
      //   sessionStorage.setItem('sidebar',''+1)
      //   this.sidebarnum = 1
      // }else if(this.$route.path=='/file'){
      //   sessionStorage.setItem('sidebar',''+2)
      //   this.sidebarnum = 2
      // }else if(this.$route.path=='/recycle'){
      //   sessionStorage.setItem('sidebar',''+3)
      //   this.sidebarnum = 3
      // }

      //路由跳转时重置滚动条顶部
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0

      
    },
    'searchText'(){
        this.setSessionItem('searchtext',this.searchText)
    },
  },
  created(){
    this.initData();
    this.GetUser()
  },
  computed: {
    key() {
      // 只要保证 key 唯一性就可以了，保证不同页面的 key 不相同
      // console.log(this.$route.fullPath);
      return this.$route.fullPath
    }
  },
  mounted(){
    window.addEventListener("setItem", () => {
      if(sessionStorage.getItem('sidebarnum')){
        this.sidebarnum = sessionStorage.getItem('sidebarnum')-1
        sessionStorage.setItem('sidebar',sessionStorage.getItem('sidebarnum'))
        sessionStorage.removeItem('sidebarnum')
      }
      if(sessionStorage.getItem("Space")=='true'){
        this.GetUser()
        sessionStorage.setItem('Space','false')
      }
      if(sessionStorage.getItem("shang")=='true'){
        this.$refs.uploads.shang()
        sessionStorage.setItem('shang','false')
      }
      if(sessionStorage.getItem("xinjian")=='true'){
        this.dialogVisible=true
        sessionStorage.setItem('xinjian','false')
      }
      if(sessionStorage.getItem("listLength")=='true'){
          this.listLength = true
      }else if(sessionStorage.getItem("listLength")=='false'){
        this.listLength = false
      }
      if(sessionStorage.getItem('success')){
        this.$message.success(sessionStorage.getItem('success'))
        let timer = setTimeout(() => {
          sessionStorage.removeItem("success")
          },100)
      }
    });
    
     // 监听返回事件,点击系统返回时
    if(window.history && window.history.pushState){
      window.addEventListener('popstate',this.reload,false)
    }
  },
   methods:{
    //查询容量
    GetUser(){
      let data = {
        userId:sessionStorage.getItem('userid')
      }
      getUser(data).then((res=>{
        this.uName = res.data.data.nickname
        this.allspace = res.data.data.allSpace/1024/1024/1024
        this.usedspace = (res.data.data.usedSpace/1024/1024/1024).toFixed(2)
      }))
    },
    //跳转到个人信息
    gto(){
      this.sidebarnum = 0
      sessionStorage.setItem('sidebar',''+0)
      this.$router.push('/information')
    },
    //注销
    cancellation(){
      sessionStorage.setItem('users','false')
      this.$message.success('退出成功！');
      this.$router.push('/login')
    },
    //获取当前url地址参数
    getQueryString(name) {
          var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
          var r = window.location.search.substr(1).match(reg);
          if (r != null) return decodeURIComponent(r[2]); return null;
            },
    //刷新数据
    reload(){
      // window.location.reload();
      this.isRouterAlive = false
      this.$nextTick(function(){
        this.isRouterAlive=true
      })
    },
    //新建文件夹
    addFolder(){
      if(!this.NewFolderName){
        this.$message.error('文件名不能为空！');
        return
      }
      let data = {
        id:sessionStorage.getItem('userid'),
        belongId:null,
        fileName:this.NewFolderName
      }
      if(sessionStorage.getItem('paths')){
            data.belongId=sessionStorage.getItem('paths')
      }else{
            data.belongId=""
      }
      addfolder(data).then(res=>{
        console.log(res)
        if(res.data.code==200){
          this.dialogVisible = false
          window.location.reload();
          this.setSessionItem('success','新建文件夹成功！')
          this.NewFolderName = null
        }else{
           this.$message.error(res.data.msg);
        }
        
      })
    },
    //根据下标关闭文件下上传
    async guanbi(index){
      await endUpload()
      
      this.$refs.uploads.deletes(index)
    },
    //更新文件的上传进度
    async uploadjindu(data){
      if(data.jindu>99){
        this.filelist[data.index].jindu=99
      }else{
        this.filelist[data.index].jindu = data.jindu
      }
    },
    //获取待上传的文件信息
    uploaddata(data){
      this.filelist.push(data)
      this.uploadlist = true
      const map = new Map()
      const newArr = this.filelist.filter(key => !map.has(key.filename) && map.set(key.filename, 1))
      this.filelist = newArr
    },
    //选择好上传文件后触发函数
    //点击上传文件
    upload(){
        this.$refs.uploads.shang()
    },
    //暂停
    zan(index){
      this.$refs.uploads.handleBtn(index)
    },
    //继续
    ji(index){
      this.$refs.uploads.handleBtns(index)
    },
    //点击上传文件夹s
    uploads(){
        this.$refs.uploadBtns.$el.click()
    },
    
    types(){
      this.index = !this.index
      this.setSessionItem('sidebartype',this.index+'')
    },
    initData(){
      this.usert=sessionStorage.getItem('users')
      if(sessionStorage.getItem('sidebar')){
        this.sidebarnum=sessionStorage.getItem('sidebar')-0
      }
      if(sessionStorage.getItem('sidebartype')){
        if(sessionStorage.getItem('sidebartype')=='true'){
          this.index = true
        }else{
          this.index=false
        }
      }
      this.GetUser()
      
    },
    Sidebars(index){
      this.$router.push(this.routers[index-1])
    },
   }
   }
</script>

<style >
.button-box {
cursor: pointer;
 padding: 10px 15px;
 border: unset;
 border-radius: 25px;
 color: #212121;
 z-index: 1;
 background: #e8e8e8;
 position: relative;
 font-weight: 1000;
 font-size: 17px;
 -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
 box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
 transition: all 250ms;
 overflow: hidden;
}

.button-box::before {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 height: 100%;
 width: 0;
 border-radius: 15px;
 background-color: #212121;
 z-index: -1;
 -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
 box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
 transition: all 250ms
}

.button-box:hover {
 color: #e8e8e8;
}

.button-box:hover::before {
 width: 100%;
}



.nulls{
  margin-left: 40%;
  margin-top: 15%;
  z-index: 2;
  position: absolute;
  transition: all 0.4s;
}
.nulls2{
  margin-left: calc(40%-240px);
  margin-top: 15%;
  z-index: 2;
  position: absolute;
  transition: all 0.4s;
}
.card-box {
  border-radius: 25px;
 cursor: pointer;
 width: 300px;
 height: 200px;
 background: rgb(255, 255, 255);
 /* background: linear-gradient(to top, #f1e1c1 0%, #fcbc97 100%); */
 padding: 2rem 1.5rem;
 transition: box-shadow .3s ease, transform .2s ease;
}

.card-info {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 transition: transform .2s ease, opacity .2s ease;
}

/*Image*/
.card-avatar {
 --size: 60px;
 /* background: linear-gradient(to top, #f1e1c1 0%, #fcbc97 100%); */
 width: var(--size);
 height: var(--size);
 border-radius: 50%;
 transition: transform .2s ease;
 margin-bottom: 1rem;
}


/*Card footer*/
.card-social {
 transform: translateY(200%);
 display: flex;
 justify-content: space-around;
 width: 100%;
 opacity: 0;
 transition: transform .2s ease, opacity .2s ease;
}

.card-social__item {
  float: left;
 list-style: none;
}

.card-social__item svg {
 display: block;
 height: 18px;
 width: 18px;
 fill: #515F65;
 cursor: pointer;
 transition: fill 0.2s ease ,transform 0.2s ease;
}

/*Text*/
.card-title {
 color: #333;
 font-size: 1.5em;
 font-weight: 600;
 line-height: 2rem;
}

.card-subtitle {
 color: #859ba8;
 font-size: 0.8em;
}

/*Hover*/
.card-box:hover {
 box-shadow: 0 8px 50px #23232333;
}

.card-box:hover .card-info {
 transform: translateY(-5%);
}

.card-box:hover .card-social {
 transform: translateY(100%);
 opacity: 1;
}

.card-social__item svg:hover {
 fill: #232323;
 transform: scale(1.1);
}

.card-avatar:hover {
 transform: scale(1.1);
}

.xuanfu{
  position: fixed;
  bottom: 10px;
  height: 30px;
  border-radius: 0px 0px 20px 20px;
  z-index: 999;
  background: rgb(255, 255, 255);
  width: 400px;
}
.upload-box{
  margin-top: 30px;
}
.imgs{
  width: 60px;
  height: 60px;
  margin-left: 20px;
  
}
.bottombox{
  background: rgb(138, 255, 199);
  height: 80px;
  
}
.n-uploadbox{
  width: 100%;
  position: absolute;
  margin-top: -80px;
  border-bottom: 1px rgb(210, 210, 210) solid;
  height: 80px;
}
.uploadtext{
  width: 400px;
  height: 30px;
  border-radius: 20px 20px 0px 0px;
  background: rgb(255, 255, 255);
  position: fixed;
  z-index: 2;
  text-align: center;
  font-size: 18px;
}
.uploadbox2{
  transition: all .3s;
  right: 10px;
  box-shadow: 0 8px 32px 0 rgba(47, 47, 47, 0.37);
  overflow: auto;
  width: 400px;
  height:500px ;
  max-height:600px ;
  position: fixed;
  z-index: 2000;
  border-radius: 20px;
  background: #ffffff;
  bottom: 10px;
}

.uploadbox{
  transition: all .3s;
  right: -450px;
  box-shadow: 0 8px 32px 0 rgba(47, 47, 47, 0.37);
  overflow: auto;
  width: 400px;
  height:500px ;
  max-height:600px ;
  position: fixed;
  z-index: 2000;
  border-radius: 20px;
  background: #ffffff;
  bottom: 10px;
}
.uploadbox::-webkit-scrollbar {
  display: none;
}


*{
    margin:0;
    padding:0;
  }
body {
  margin:0;
  padding:0;
  border:0;
}
body::-webkit-scrollbar {
  display: none;
}
.NewFolder{
  margin-top: 20px;
  margin-left: 140px;
}
.el-dialog{
  position: absolute;
}
.el-dialog__body{
  height: 150px;
}
.expandbox{
  top: 25px;
  position: fixed;
  width: 240px;
  right: -80px;
  cursor: pointer;
}
.transmission{
  font-size: 30px;
  position: fixed;
  right: 180px;
  top: 25px;
  cursor: pointer;
}
.transmission:hover{
  color: rgb(33, 155, 254);
}
.searchbox{
  top: 25px;
  position: fixed;
  width: 240px;
  right: 230px;
}

.el-breadcrumb{
  margin-top: 33px;
  margin-left: 20px;
  
}
.el-breadcrumb__item{
  font-size: 22px;
}
.Toptaskbar{
  position: fixed;
  z-index: 200;
  width: 1920px;
  margin-left: 240px;
  height: 90px;
  transition: all .4s;
  background: rgb(255, 255, 255);
}
.Toptaskbar2{
  width: 1920px;
  z-index: 200;
  position: fixed;
  background: rgb(255, 255, 255);
  height: 90px;
  transition: all .4s;
}
.LOGO{
  width: 100%;
  height: 70px;
  line-height: 90px;
}

.che{
  width: 22px;
  height: 22px;
  float: left;
  margin-top: 15px;
}

.logos{
  float: left;
  margin-left: 20px;
  margin-top: 20px;
}
.LOGO-text{
  font-size: 20px;
  margin-left: 15px;
  font-weight: 700;
}
.foots-text{
  font-size: 14px;
  color: rgb(55, 55, 55);
}
.foots-text2{
  float: right;
  font-size: 14px;
  color: rgb(21, 108, 184);
  cursor: pointer;
}
.foots-text2:hover{
  color: rgb(29, 149, 255);
}
.foots{
  position: absolute;
  bottom: 0;
  margin-bottom: 40px;
  width: 90%;
  margin-left: 5%;
  height: 40px;
}
.User{
  width: 100%;
  height: 70px;
  line-height: 70px;
}
.hrad{
  font-size: 30px;
  float: left;
  margin-left: 20px;
}
.foot{
  float: right;
  margin-right: 20px;
  cursor:pointer;
}
.el-dropdown{
  height: 30px;
}
.el-dropdown-link{
  font-size: 30px;
  
}
.el-dropdown-link:hover{
  color: rgb(33, 155, 254);
}
.card{
  margin-left: 10px;
}
.cards{
  padding-left: 10px;
  font-size: 25px;
  width: 210px;
  height: 50px;
  line-height: 50px;
  cursor:pointer;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
.cards2{
  padding-left: 10px;
  background: rgb(230, 230, 230);
  border-radius: 15px;
  font-size: 25px;
  width: 210px;
  height: 50px;
  line-height: 50px;
  cursor:pointer;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
.cards3{
  padding-left: 10px;
  background: rgb(208, 208, 208);
  border-radius: 15px;
  font-size: 25px;
  width: 210px;
  height: 50px;
  line-height: 50px;
  cursor:pointer;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}

.textr{
  font-size: 16px;
  float: left ;
  margin-left: 10px;
}

.sidebar{
  background: rgb(243, 243, 243);
  position: fixed;
  width: 240px;
  height: 100vh;
  transition: all .4s;
  float: left;
  z-index: 100;
  
}
.sidebar2{
  position: fixed;
  margin-left: -240px;
  width: 240px;
  height: 100vh;
  transition: all .4s;
  float: left;
  z-index: 100;
  overflow: hidden;
}


.fade-enter-active {
  transition: all .2s;
}
.fade-enter {
  opacity: 0;
  transform: translateX(40px);
}
.bodu{
  margin-left: 240px;
  transition: all .4s;
}
.bodu2{
  margin-left: 0px;
  transition: all .4s;
}
.anniu{
  position: fixed;
  z-index: 990;
  float: left;
  margin-left: 235px;
  font-size: 40px;
  line-height: 100vh;
  transition: all .4s;
}
.anniu2{
  position: fixed;
  z-index: 990;
  float: left;
  margin-left: -10px;
  font-size: 40px;
  line-height: 100vh;
  transition: all .4s;
}
.el-icon-caret-left,.el-icon-caret-right{
  color: rgb(209, 209, 209);
}
.el-icon-caret-left:hover{
  color: rgb(28, 183, 255);
  cursor:pointer;
}
.el-icon-caret-right:hover{
  color: rgb(28, 183, 255);
  cursor:pointer;
}
</style>


