<template>
  <div >
    404
  </div>
</template>

<script type="text/javascript">
   export default {
   data() {
       return {
        
       }
   },
   methods:{
   }
   }
</script>

<style scoped>
</style>
